import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Seo, Layout, OtherPageWrapper, SeoPage } from "components"

const Page = () => (
  <Layout>
    <Seo
      title="Best Coffee in Newhall"
      description="A great cup of coffee should do more than wake you up. We serve intelligentsia with our house made coffee creamers. You can pick from Mexican hot chocolate, vanilla or streusel. We promise this will leave a lasting impression on you."
      keywords="best coffee, best coffee near me, best coffee in Newhall, best coffee in Santa Clarita"
    />
    <OtherPageWrapper title="Best Coffee in Newhall" />
    <SeoPage
      title="Best Coffee in Newhall"
      name="Christine Morong"
      review="Great food. Excellent coffee. Love their home made jams especially the strawberry earl Grey. DELICIOUS!!!!!!"
    >
      <StaticImage
        imgStyle={{ borderRadius: "20px" }}
        src="../images/best-coffee.jpeg"
        alt="Best Coffee in Newhall"
        placeholder="blurred"
      />
    </SeoPage>
  </Layout>
)

export default Page
